.notification-box {
  position: fixed;
  left: 0;
  top: -100%;
  right: 0;
  width: 100%;
  z-index: 9999;
  transition: 0.3s all;
  margin-top: $safeTop;
  &.show {
    top: 0;
  }
}

.notification-dialog.android-style {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1), 0 11px 25px 0 rgba(0,0,0,0.18);
  padding: 20px 16px 24px 16px;
  .notification-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .in {
      display: flex;
      align-items: center;
      .imaged {
        margin-right: 8px;
      }
      strong {
        font-size: $fontSizeSub;
        font-weight: $regular;
        color: $colorHeading;
      }
      span {
        display: inline-flex;
        align-items: center;
        margin-left: 6px;
        font-size: $fontSizeCaption;
        &:before {
          display: inline-flex;
          align-items: center;
          margin-right: 6px;
          content: "•";
        }
      }
    }
    .close-button {
      display: flex;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: $colorLight;
      &:hover,
      &:active {
        color: $colorText;
      }
    }
  }
  .notification-content {
    font-size: $fontSizeSub;
    line-height: 1.4em;
    margin-top: 14px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .subtitle {
      font-size: $fontSize;
      margin: 0 0 5px 0;
      line-height: 1.4em;
      font-weight: $medium;
    }
    .in {
      padding-right: 16px;
    }
  }
  .icon-box {
    width: 46px;
    height: 46px;
    font-size: 46px;
    color: $colorLight;
  }
  .notification-footer {
    border-top: 1px solid $colorLine;
    margin: 16px -16px -24px -16px;
    padding: 0;
    display: flex;
    align-items: flex-start;
    .notification-button {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $colorPrimary !important;
      font-size: $fontSizeSub;
      border-right: 1px solid $colorLine;
      padding: 16px 6px;
      ion-icon,
      .icon{
        font-size: 20px;
        line-height: 1em;
        margin-right: 8px;
      }
      &:last-child {
        border-right: 0;
      }
      &:hover,
      &:active {
        color: darken($colorPrimary, 5%);
        background: rgba($colorLine, 0.3);
      }
    }
  }
}

.notification-dialog.ios-style {
  margin: 10px;
  background: #3b4859;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  .notification-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 0 0;
    .in {
      display: flex;
      align-items: center;
      .imaged {
        margin-right: 8px;
      }
      strong {
        font-size: $fontSizeSub;
        font-weight: $regular;
        color: #fff;
      }
    }
    .right {
      display: flex;
      align-items: center;
      span {
        display: inline-flex;
        align-items: center;
        margin-right: 6px;
        color: rgba(255, 255, 255, 0.5);
        font-size: $fontSizeCaption;
      }
    }
    .close-button {
      display: flex;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #fff;
      opacity: 0.5;
      &:hover,
      &:active {
        opacity: 0.7;
      }
    }
  }
  .icon-box {
    width: 46px;
    height: 46px;
    font-size: 46px;
    color: $colorLight;
  }
  .notification-content {
    font-size: $fontSizeSub;
    line-height: 1.4em;
    padding: 14px 16px 20px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.75);
    .subtitle {
      font-size: $fontSize;
      margin: 0 0 5px 0;
      line-height: 1.4em;
      color: #fff;
      font-weight: $medium;
    }
    .in {
      padding-right: 16px;
    }
  }
  .notification-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin: 0px -16px -16px -16px;
    padding: 0;
    display: flex;
    align-items: flex-start;
    .notification-button {
      color: #fff !important;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $fontSizeSub;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      padding: 15px 6px;
      ion-icon {
        font-size: 20px;
        line-height: 1em;
        margin-right: 10px;
        color: #fff;
      }
      &:last-child {
        border-right: 0;
      }
      &:hover,
      &:active {
        color: darken($colorPrimary, 5%);
        background: rgba(255, 255, 255, 0.02);
      }
    }
  }
}
