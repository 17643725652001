.slide-enter,
.slide-exit {
  transition: transform 300ms ease-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

/* Hide previous page */
.slide-exit {
  opacity: 0;
}

.slide-exit-active {
  transform: translateX(-100%);
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  border-radius: 10px;
}

.video-wrapper iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}
.appBottomMenu {
  .col {
    img {
        height: 26px;
        width: auto;
        opacity: 0.6;
    }
  }
}







.full-height-container {
  height: calc(100vh - 56px);
}
.lucky-draw-container {
  padding: 15vh 0 56px!important;
  background-size: cover;
  background-position: center;

  .center-cycle {
    cursor: pointer;
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 9;

    .content {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;

      img {
        width: 50px;
        height: auto;
      }

      .num-turn {
        position: absolute;
        top: 28px;
        color: #000;
        text-align: center;
        font-weight: 800;
        font-size: 13px;
        line-height: normal;
      }
    }
  }

  .luckywheel-bottom {
    margin-top: -20px;
    text-align: center;

    img {
      width: 100px;
      max-width: 50%;
    }
  }

  .buttonWrapper {
    cursor: pointer;
    background-color: #fc3;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto 26px;
    font-weight: 600;
    font-size: 18px;
    border-radius: 8px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-color: #fc3;
    position: relative;
    opacity: 1;
  }

  .btn-disable {
    cursor: not-allowed;
    opacity: .7;
    pointer-events: none;
  }

  .note-turn {
    font-size: 14px;
    line-height: 18px;
    font-weight: 100;
    color: #fff;
    padding: 0 5px;
    text-align: center;
  }
}



//#main {
//  height: 80vh;
//  width: 100vw;
//  overflow: hidden;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}

$spinner-size:          80vw;

.spinner-list{
  margin: 0;
}
.spinner {
  --spin-time: 3000;
  position: relative;
  width: $spinner-size;
  padding-bottom: 80%;
  margin: 0 auto;
  ul {
    padding-left: 0;
  }
  //&:before,
  //&:after {
  //  content: '';
  //  display: block;
  //  position: absolute;
  //}
  //&:before,
  //&:after {
  //  z-index: 10;
  //  top: -1px;
  //  left: 50%;
  //  transform: translateX(-50%);
  //  width: 0;
  //  height: 0;
  //  border-left: 1vw solid transparent;
  //  border-right: 1vw solid transparent;
  //  border-top: 3vw solid lightgrey;
  //}
  //&:after {
  //  z-index: 9;
  //  margin-left: -2px;
  //  top: 0;
  //  opacity: 0.4;
  //  border-top: 3.2vw solid #000;
  //}
  &-list {
    transition: transform calc(var(--spin-time) * 1ms) ease-out;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 3vw #f8bb15, 0px 0px 0px 3vw #f8bb15;
    &:after {
      content: '';
      display: block;
      width: 1.7vw;
      height: 1.7vw;
      background-color: #3c3434;
      position: absolute;
      border-radius: 50%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
  &-slice {
    position: absolute;
    display: flex;
    top: 50%;
    transform-origin: right;
    list-style: none;
    padding-left: 20px;
    //height: $slice-size;
    //width: $spinner-size/2;
    justify-content: flex-start;
    align-items: center;
    z-index: -1;
    //&:after {
    //  content: '';
    //  position: absolute;
    //  top: 0;
    //  width: 0;
    //  height: 0;
    //  border-left: $spinner-size/2 solid;
    //  border-bottom: $slice-size/1.95 solid transparent;
    //  border-top: $slice-size/1.95 solid transparent;
    //}
    &-text {
      z-index: 1;
      text-align: center;
      //padding: 0.5em 6em 0.5em 1em;
      max-width: 100%;
    //  font-size: calc(#{$spinner-size} / 30);
      font-size: 12px;
      transform: translate3d( 0, 0, 0);
      backface-visibility: visible;
      margin: 0;
      transform: rotate(-90deg);
      margin-left: 15px;


      width: min-intrinsic;       /* old Chrome, Safari */
      width: -webkit-min-content; /* less old Chrome, Safari */
      width: -moz-min-content;    /* current Firefox */
      width: min-content;         /* current Chrome, Safari; not IE or Edge */


      //display: -webkit-box;
      //-webkit-line-clamp: 2;
      //-webkit-box-orient: vertical;
      //overflow: hidden;
      //text-overflow: ellipsis;
      line-height: 1.2em;
      padding: 0;
    }
  }
  &-lever {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    bottom: 0;
    margin: auto;
    border: 0.1vw solid grey;
    background-color: lightgrey;
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    //&:before,
    //&:after {
    //  content: '';
    //  display: block;
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  margin: auto;
    //}
    //&:before {
    //  top: 0;
    //  bottom: 0;
    //  background-color: grey;
    //  height: 80%;
    //  width: 1.2vw;
    //}
    //&:after {
    //  top: 0;
    //  bottom: 0;
    //  margin: auto;
    //  height: 35%;
    //  width: 0.8vw;
    //  background-color: lightgrey;
    //  transform: translateZ(0) translateY(-25%);
    //  backface-visibility: hidden;
    //  .is-spinning & {
    //    animation: movePole 3000ms ease-in-out;
    //  }
    //  @at-root {
    //    @keyframes movePole {
    //      0%, 100% {
    //        transform: translateZ(0) translateY(-25%);
    //        height: 35%;
    //      }
    //      5%, 50% {
    //        height: 10%;
    //      }
    //      10% {
    //        transform: translateZ(0) translateY(50%);
    //        height: 35%;
    //      }
    //      15% {
    //        height: 35%;
    //      }
    //    }
    //  }
    //}
    &-button {
      text-indent: -9999px;
      position: absolute;
      border: 0;
      background: transparent;
      padding: 0;
      width: 1.8vw;
      height: 1.8vw;
      background-color: red;
      border-radius: 100%;
      cursor: pointer;
      top: calc(25% - #{1.8/2 * 1vw});
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
      .is-spinning & {
        animation: moveLever 3000ms ease-in-out;
      }
      @at-root {
        @keyframes moveLever {
          0%, 100% {
            transform: translateY(0%)
          }
          10% {
            transform: translateY(240%);
          }
        }
      }
    }
  }
}

//@for $i from 1 through $spinner-slices {
//  .spinner-slice:nth-of-type(#{$i}) {
//    transform: translateY(-50%) rotate(30deg * $i);
//    &:after {
//      border-left-color: nth($slice-colors, $i);
//    }
//  }
//}


//
//@keyframes spin {
//  from {
//    transform:rotate(0deg);
//  }
//  to {
//    transform:rotate(360deg);
//  }
//}
.header-main {
  background: #fff;
}
.header-bottom {
  background-color: #ffba00;

  .navbar {
    padding: 5px 0;
  }

  .navbar-nav {
    .nav-link {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: .02em;
      text-transform: uppercase;
      color: #fff;
      margin: 0 13px;

      &:hover {
        color: #000;
      }
    }
  }
}
.logo {
  img {
    max-height: 80px;
    padding: 5px 0;
  }
}

.home-page {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}