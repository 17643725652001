
body{
  font-family: $fontFamily;
  font-size: $fontSize;
  line-height: $bodyLineHeight;
  letter-spacing: $bodyLetterSpacing;
  color: $colorText;
  background: $bodyBackground;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}
a{
  color: $colorPrimary;
  outline: 0 !important;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus{
    outline: 0 !important;
    color: $colorPrimary;
    text-decoration: none;
  }
}
// hide all the scroll bars
::-webkit-scrollbar {
  width: 0;
}